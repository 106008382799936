import Vue from "vue";
import Vuex from "vuex";
import createMutationsSharer from "vuex-shared-mutations";
import api from "../services/UserService"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    session: {
      loggedIn: false,
      user: {}
    },
    klnPropertyRecords: [],
    ntepPropertyRecords: [],
    ntwPropertyRecords: [],
    hkiPropertyRecords: [],
    klnRentRecords: [],
    ntepRentRecords: [],
    ntwRentRecords: [],
    hkiRentRecords: [],
    klnOldPropertyRecords: [],
    ntepOldPropertyRecords: [],
    ntwOldPropertyRecords: [],
    hkiOldPropertyRecords: [],
    klnOldRentRecords: [],
    ntepOldRentRecords: [],
    ntwOldRentRecords: [],
    hkiOldRentRecords: []

  },
  mutations: {
    mutateSessionLogin(state, { user }) {
      state.session.loggedIn = true;
      state.session.user = user;
    },
    mutateSessionLogout(state) {
      state.session.loggedIn = false;
    },
    mutatePropertyRecords(state, payload) {

      console.log('mutatePropertyRecords :' + payload.records.length + " " + payload.type + " " + payload.area + " " + payload.latest);
      if(payload.type == '買賣') {
        if(payload.area == '九龍') {
          if(payload.latest) {
            state.klnPropertyRecords = payload.records;
          }
          else {
            state.klnOldPropertyRecords = payload.records;
          }
        }
        else if(payload.area == '新界東') {
          if(payload.latest) {
            state.ntepPropertyRecords = payload.records;
          }
          else {
            state.ntepOldPropertyRecords = payload.records;
          }
        }
        else if(payload.area == '新界西') {
          if(payload.latest) {
            state.ntwPropertyRecords = payload.records;
          }
          else {
            state.ntwOldPropertyRecords = payload.records;
          }
        }
        else if(payload.area == '港島') {
          if(payload.latest) {
            state.hkiPropertyRecords = payload.records;
          }
          else {
            state.hkiOldPropertyRecords = payload.records;
          }
        }
      }
      else if(payload.type == '租賃') {
        if(payload.area == '九龍') {
          if(payload.latest) {
            state.klnRentRecords = payload.records;
          }
          else {
            state.klnOldRentRecords = payload.records;
          }
        }
        else if(payload.area == '新界東') {
          if(payload.latest) {
            state.ntepRentRecords = payload.records;
          }
          else {
            state.ntepOldRentRecords = payload.records;
          }
        }
        else if(payload.area == '新界西') {
          if(payload.latest) {
            state.ntwRentRecords = payload.records;
          }
          else {
            state.ntwOldRentRecords = payload.records;
          }
        }
        else if(payload.area == '港島') {
          if(payload.latest) {
            state.hkiRentRecords = payload.records;
          }
          else {
            state.hkiOldRentRecords = payload.records;
          }
        }
      }
    }
  },
  actions: {
    async fetchRecords(context, {type, area, latest}) {
      try {
        // time the request
        console.time("fetchRecords");
        await api.get(`/api/getPropertyRecords?type=${type}&area=${area}&latest=${latest}`).then(function (response) {
          console.log(response.data.propertyRecords.length);
          console.timeEnd("fetchRecords");
          var payload = {};
          payload.records = Object.freeze(response.data.propertyRecords);
          payload.type = type;
          payload.area = area;
          payload.latest = latest;
          context.commit('mutatePropertyRecords', payload);
        });
      } catch (err) {
        console.log(err);
      }
    }
  },
  getters: {
    getSession(state) {
      return state.session;
    },
    getPropertyByFilter(state) {
      return (area,type,latest) => {
        if (type=='買賣') {
          if (area=='九龍') {
            if (latest) {
              return state.klnPropertyRecords;
            }
            else {
              return state.klnOldPropertyRecords;
            }
          } 
          else if (area=='新界東') {
            if (latest) {
              return state.ntepPropertyRecords;
            }
            else {
              return state.ntepOldPropertyRecords;
            }
          }
          else if (area=='新界西') {
            if (latest) {
              return state.ntwPropertyRecords;
            }
            else {
              return state.ntwOldPropertyRecords;
            }
          }
          else if (area=='港島') {
            if (latest) {
              return state.hkiPropertyRecords;
            }
            else {
              return state.hkiOldPropertyRecords;
            }
          }
          else {
            return [];
          }
        }
        else if (type=='租賃') {
          if (area=='九龍') {
            if (latest) {
              return state.klnRentRecords;
            }
            else {
              return state.klnOldRentRecords;
            }
          } 
          else if (area=='新界東') {
            if (latest) {
              return state.ntepRentRecords;
            }
            else {
              return state.ntepOldRentRecords;
            }
          }
          else if (area=='新界西') {
            if (latest) {
              return state.ntwRentRecords;
            }
            else {
              return state.ntwOldRentRecords;
            }
          }
          else if (area=='港島') {
            if (latest) {
              return state.hkiRentRecords;
            }
            else {
              return state.hkiOldRentRecords;
            }
          }
          else {
            return [];
          }
        }
        else return [];
      }

    }
  },
  modules: {},
  plugins: [createMutationsSharer({ predicate: ["mutatePriceRecords"] })]
});
