const axios = require('axios');

export async function getAllUsers() {

    const response = await axios.get('/api/users');
    return response.data;
}

export async function createUser(data) {
    const response = await axios.post(`/api/user`, {user: data});
    return response.data;
}

const api = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_API,
  withCredentials: true
});

export default api;
