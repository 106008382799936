<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: 'App',
  components: {
  },
  data() {
      return {
          profiles: [
              { id: 1, name: 'ALL_METALS'}, 
              { id: 2, name: 'M5_METALS'},
              { id: 3, name: 'MT4'}, 
              { id: 4, name: 'MT4_MT5_METALS'}
          ],
          sortBy: 'date',
          sortDesc: true,
          fields: [
            {
                key:"serverName",
                sortable: true,
            },
            {
                key:"symbol",
                sortable: true,
            },
            {
                key:"price",
            },
            {
                key:"priceStatus",
            },
            {
                key:"spread",
            },
            {
                key:"time",
            },
            {
                key:"type",
                sortable: true,
            }
          ],
          priceRecords: [
          { id: '1', serverName: 'MT4Live01', symbol: 'GOLD', price: '1978.73/1979.23', priceStatus: '0/11(0.18)', spread: '0.5', time: '18:14:27[00:00:00]', type:'GOLD' }, 
          { id: '2', serverName: 'MT4Live02', symbol: 'GOLD1000', price: '1978.63/1979.13', priceStatus: '0/12(0.12)', spread: '0.5', time: '18:14:26[00:00:02]', type:'GOLD' },
          { id: '3', serverName: 'MT4Live01', symbol: 'GOLD', price: '1978.73/1979.23', priceStatus: '0/11(0.18)', spread: '0.5', time: '18:14:27[00:00:00]', type:'GOLD' }, 
          { id: '4', serverName: 'MT4Live02', symbol: 'GOLD1000', price: '1978.63/1979.13', priceStatus: '0/12(0.12)', spread: '0.5', time: '18:14:26[00:00:02]', type:'GOLD' },
          { id: '5', serverName: 'MT4Live01', symbol: 'GOLD', price: '1978.73/1979.23', priceStatus: '0/11(0.18)', spread: '0.5', time: '18:14:27[00:00:00]', type:'GOLD' }, 
          { id: '6', serverName: 'MT4Live02', symbol: 'GOLD1000', price: '1978.63/1979.13', priceStatus: '0/12(0.12)', spread: '0.5', time: '18:14:26[00:00:02]', type:'GOLD' },
          { id: '7', serverName: 'MT4Live01', symbol: 'GOLD', price: '1978.73/1979.23', priceStatus: '0/11(0.18)', spread: '0.5', time: '18:14:27[00:00:00]', type:'GOLD' }, 
          { id: '8', serverName: 'MT4Live02', symbol: 'GOLD1000', price: '1978.63/1979.13', priceStatus: '0/12(0.12)', spread: '0.5', time: '18:14:26[00:00:02]', type:'GOLD' },
          { id: '9', serverName: 'MT4Live01', symbol: 'GOLD', price: '1978.73/1979.23', priceStatus: '0/11(0.18)', spread: '0.5', time: '18:14:27[00:00:00]', type:'GOLD' }, 
          { id: '10', serverName: 'MT4Live02', symbol: 'GOLD1000', price: '1978.63/1979.13', priceStatus: '0/12(0.12)', spread: '0.5', time: '18:14:26[00:00:02]', type:'GOLD' },
          { id: '11', serverName: 'MT4Live01', symbol: 'GOLD', price: '1978.73/1979.23', priceStatus: '0/11(0.18)', spread: '0.5', time: '18:14:27[00:00:00]', type:'GOLD' }, 
          { id: '12', serverName: 'MT4Live02', symbol: 'GOLD1000', price: '1978.63/1979.13', priceStatus: '0/12(0.12)', spread: '0.5', time: '18:14:26[00:00:02]', type:'GOLD' },
          { id: '13', serverName: 'MT4Live01', symbol: 'GOLD', price: '1978.73/1979.23', priceStatus: '0/11(0.18)', spread: '0.5', time: '18:14:27[00:00:00]', type:'GOLD' }, 
          { id: '14', serverName: 'MT4Live02', symbol: 'GOLD1000', price: '1978.63/1979.13', priceStatus: '0/12(0.12)', spread: '0.5', time: '18:14:26[00:00:02]', type:'GOLD' },
          
          ]
      }
  },
  mounted() {
    console.log("I am in mounted!!!")
    this.settings = true
  },
  setup () {
    console.log("I am in setup!!!")
  }
}
</script>

<style>
  @import '../../assets/styles/global.css';
</style>